@tailwind base;

/* Your own custom base styles */

:root {
	--fade-delay: 0ms;
	--slide-delay: 0ms;
}

.box-grid {
	background: repeating-linear-gradient(#00000050 4rem, #00000050 4.1rem, transparent 4.1rem 100px),
				repeating-linear-gradient(0.25turn, #00000050 4rem, #00000050 4.1rem, transparent 4.1rem 100px);
}

.scrollbar-none::-webkit-scrollbar {
	display: none;
}

.scrollbar-none {
	-ms-overflow-style: none;
}

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
